import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { Tour, TourProps, Skeleton } from "antd";
import { ReactComponent as CreateWorkSpace } from "../../assets/images/work_space/create_img.svg";
import { ReactComponent as ComunitiWorkSpace } from "../../assets/images/work_space/community1.svg";
import { ReactComponent as Disclcon } from "../../assets/images/work_space/iconTest.svg";
import { ReactComponent as TemplateWorkSpace } from "../../assets/images/work_space/template.svg";
import { ReactComponent as SearchIcon } from "../../assets/images/work_space/search.svg";
import { ReactComponent as ArrowR } from "../../assets/images/work_space/arrow-right.svg";
import { ReactComponent as ArrowD } from "../../assets/images/work_space/arrow-down.svg";
import Table, {
  makeData,
  NameCell,
  ButtonCell,
  DateCell,
  StatusCell,
  ServerTypeCell,
  SharedUsersCell,
} from "./viewer/TableWorkspace";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../reducers";
import { ReduxActionTypes } from "../../constants/ReduxActionConstants";
import SideNavigation from "./SideNavigation";
import CreateOrgModal from "../../components/workspace/CreateOrgModal";
import { getCurrentUser, getWorkspaceTour } from "selectors/usersSelectors";
import { sendUserLogs } from "api/userLogs";
import { finishUserTourHandler } from "pages/organization/helpers";

const Styles = styled.div`
  padding: 1rem;

  table {
    // min-height: 490px;
    border-spacing: 0;
    border: 1px solid #ececec;
    width: 100%;
    .expanded {
      padding: 0px;
    }
    .header_table {
      cursor: pointer;
      display: flex;
      align-items: center;
    }
    .sorting_table {
      padding-left: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-flow: column;
    }
    tbody {
      tr {
        height: 44px !important;
      }
    }
    tbody {
      tr {
        :hover {
          background: #f1f1f1;
        }
      }
    }
    thead {
      tr {
        th {
          :first-child {
            .sorting_table {
              display: none;
            }
          }
          color: #6c767e !important;
          font-family: "Roboto";
          font-style: normal;
          font-size: 12px;
          line-height: 20px;
          text-align: left;
        }
      }
    }
    tr {
      height: 50px;
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th,
    td {
      vertical-align: middle;
      line-height: 1px;
      margin: 0;
      padding: 5px;
      border-bottom: 1px solid #ececec;
      border-right: 1px solid #ececec;
      :first-child {
        width: 32px;
        text-align: center;
        span {
          display: inline;
        }
      }
      :nth-child(2) {
        /* color: #3b82ff; */
        width: 370px;
      }
      :nth-child(3) {
        // Status item
        width: 100px;
      }
      :nth-child(5) {
        // shared item
        width: 65px;
      }
      :nth-child(6) {
        // updated time
        width: 185px;
      }
      :last-child {
        border-right: 0;
      }
    }
  }
`;

const WrapperWorkspace = styled.div`
  /* padding: 72px 24px 0 72px; */

  .header_work_space {
    font-family: "Roboto";
    display: flex;
    justify-content: space-between;
    .item_header {
      background: #ffffff;
      border: 1px solid #d9d9d9;
      box-sizing: border-box;
      box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.05);
      border-radius: 4px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 15px 15px 0px 15px;
      width: 450px;
      overflow: hidden;
      position: relative;
      .default_text {
        font-family: "Roboto";
        font-style: normal;
        font-weight: bold;
        font-size: 30px;
        line-height: 35px;
        z-index: 1;
        background: linear-gradient(
          180deg,
          #3b82ff 0%,
          rgba(59, 130, 255, 0) 100%
        );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
      .default_img {
        position: absolute;
        right: -20px;
        bottom: -30px;
      }
      .but_div {
        position: absolute;
        left: 123px;
        height: 106px;
        width: 133px;
        cursor: pointer;
        bottom: 17px;
      }
      .wrapper_button {
        position: reletive;
        .title {
          font-size: 20px;
          line-height: 23px;
          color: #495870;
          margin-bottom: 15px;
        }
        .description {
          font-size: 14px;
          line-height: 18px;
          color: #6c767e;
          margin-bottom: 10px;
        }

        .button {
          button {
            width: 73px;
            height: 32px;
            background: #e9efff;
            border-radius: 4px;
            border: none;
            font-size: 14px;
            line-height: 16px;
            color: #3b82ff;
            transition: all 0.1s;
          }
          button: hover {
            transform: scale(1.05);
            cursor: pointer;
          }
          a {
            display: inline-block;
            text-align: center;
            height: 32px;
            background: #e9efff;
            border-radius: 4px;
            border: none;
            font-size: 14px;
            line-height: 32px;
            padding-left: 13px !important;
            padding-right: 13px !important;
            padding: 0px 5px;
            color: #3b82ff;
            transition: all 0.1s;
          }
          a:hover {
            transform: scale(1.05);
            cursor: pointer;
          }
        }
      }
    }
  }
`;

const MainContainer = styled.div`
  background: #ffffff;
  border: 1px solid #d9d9d9;
  box-sizing: border-box;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  margin-top: 15px;
  padding-top: 16px;
  font-family: "Roboto";
  .header {
    background: #e9efff;
  }
  .container_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .wrapper_title {
      display: flex;
      align-items: center;
    }
    .title {
      font-size: 20px;
      line-height: 23px;
      margin-left: 16px;
      margin-right: 8px;
    }
    .create {
      width: 24px;
      height: 24px;
      background: #3b82ff;
      color: white;
      font-size: 20px;
      position: relative;
      border-radius: 20px;
      margin-right: 25px;
      span {
        cursor: pointer;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
    .search {
      position: relative;
      input {
        width: 200px;
        height: 34px;
        background: #e9efff;
        border-radius: 4px;
        padding: 8px 16px;
        border: none;
      }
      svg {
        position: absolute;
        top: 50%;
        right: 10px;
        transform: translateY(-50%);
      }
    }
  }
  .filter {
    position: relative;
    width: 68px;
    height: 34px;
    padding: 8px;
    background: #e9efff;
    border-radius: 6px;
    margin-right: 16px;
    span {
      color: #000165;
      font-size: 14px;
      line-height: 18px;
      display: block;
      text-align: right;
    }
    svg {
      position: absolute;
      top: 50%;
      left: 10px;
      transform: translateY(-50%);
    }
  }
`;

const StyledSkeleton = styled(Skeleton)`
  .ant-skeleton-content > .ant-skeleton-paragraph {
    li {
      height: 40px;
    }
  }
`;

export const WorkSpace = (props: any) => {
  const refOnboardingStart = useRef(null);
  const refEnterWorkspaceName = useRef(null);
  const refShowAllWorkspace = useRef(null);
  const refOpenWorkspace = useRef(null);
  const userOrgs = useSelector(
    (state: AppState) => state.ui.applications.userOrgs,
  );
  const isFetchingApplications = useSelector(
    (state: AppState) => state.ui.applications.isFetchingApplications,
  );

  const childRef = useRef({
    filter: (e: string) => {
      console.log("filter");
    },
  });
  const modalRef = useRef({
    open: () => {
      console.log("open modal");
    },
  });
  const dispatch = useDispatch();
  useEffect(() => {
    // dispatch({
    //   type: ReduxActionTypes.PREV_APP,
    //   payload: "",
    // });
    dispatch({
      type: ReduxActionTypes.CUR_APP,
      payload: "",
    });
    dispatch({ type: ReduxActionTypes.GET_ALL_APPLICATION_INIT });
  }, []);

  const columns: any = React.useMemo(
    () => [
      {
        Header: () => null,
        id: "expander",
        // eslint-disable-next-line react/display-name
        Cell: (Row: any) => (
          <span {...Row.row.getToggleRowExpandedProps()}>
            {Row.row.isExpanded ? <ArrowD></ArrowD> : <ArrowR></ArrowR>}
          </span>
        ),
      },
      {
        Header: "Name",
        accessor: "name",
        Cell: NameCell,
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: StatusCell,
      },
      {
        Header: "Server type",
        accessor: "serverPlanName",
        Cell: ServerTypeCell,
      },
      {
        Header: "Members",
        accessor: "sharedUsersCount",
        Cell: SharedUsersCell,
      },
      // {
      //   Header: "Create Date",
      //   sortType: "datetime",
      //   Cell: DateCell,
      //   accessor: (d: any) => {
      //     return new Date(d.create_date);
      //   },
      // },
      {
        Header: "Update Date",
        sortType: "datetime",
        Cell: DateCell,
        accessor: (d: any) => {
          return new Date(d.update_date);
        },
      },
      {
        Header: "",
        sortable: false,
        accessor: "buttons",
        Cell: ButtonCell,
      },
    ],
    [],
  );

  const data = React.useMemo(() => makeData(userOrgs, refOpenWorkspace), [
    userOrgs,
  ]);

  const renderRowSubComponent = React.useCallback(
    ({ row }) => (
      <pre
        style={{
          fontSize: "10px",
        }}
      >
        <code>{JSON.stringify({ values: row.values }, null, 2)}</code>
      </pre>
    ),
    [],
  );

  const [
    currentStepCreateWorkspaceTour,
    setCurrentStepCreateWorkspaceTour,
  ] = useState<number>(0);
  const [
    currentStepOpenWorkspaceTour,
    setCurrentStepOpenWorkspaceTour,
  ] = useState<number>(0);

  const stepsCreateWorkspace: TourProps["steps"] = [
    {
      title: "The first step is to create a workspace.",
      target: () => refOnboardingStart.current,
      placement: "right",
      nextButtonProps: {
        style: { display: "none" },
      },
      prevButtonProps: {
        style: { display: "none" },
      },
    },
    {
      title: "Enter the name of the workspace and click the Create button",
      target: () => refEnterWorkspaceName.current,
      nextButtonProps: {
        style: { display: "none" },
      },
      prevButtonProps: {
        style: { display: "none" },
      },
    },
  ];

  const stepsOpenWorkspace: TourProps["steps"] = [
    {
      placement: "top",
      title:
        "A list of all your workspaces is displayed here. You can delete or open them",
      target: () => refShowAllWorkspace.current,
      nextButtonProps: {
        onClick: () => setCurrentStepOpenWorkspaceTour(prev => prev + 1),
        //   style: { display: "none" },
      },
      prevButtonProps: {
        style: { display: "none" },
      },
    },
    {
      title: "To open the created workspace, you need to click the Open button",
      target: () => refOpenWorkspace.current,
      nextButtonProps: {
        style: { display: "none" },
      },
      prevButtonProps: {
        style: { display: "none" },
      },
    },
  ];

  const userTours = useSelector((state: any) => getWorkspaceTour(state));
  const user = useSelector((state: any) => getCurrentUser(state));

  const [isShowCreateWorkspaceTour, setIsShowCreateWorkspaceTour] = useState(
    false,
  );

  const [isShowOpenWorkspaceTour, setIsShowOpenWorkspaceTour] = useState(false);

  useEffect(() => {
    if (userTours) {
      if (userTours.createWorkspace.isOpen) setIsShowCreateWorkspaceTour(true);

      if (userTours.openWorkspace.isOpen) setIsShowOpenWorkspaceTour(true);
    }
  }, [userTours]);

  const styles = {
    padding:
      window.location.pathname === "/workspace"
        ? "72px 24px 0 24px"
        : "72px 24px 0 72px",
  } as React.CSSProperties;

  // const styles = {
  //   {padding: window.location.pathname === "/workspace"
  //   ? "72px 24px 0 24px"
  //   : "72px 24px 0 72px"}
  // }

  return (
    <WrapperWorkspace style={styles}>
      {userTours && (
        <Tour
          open={isShowCreateWorkspaceTour}
          onClose={() => {
            setIsShowCreateWorkspaceTour(false);

            finishUserTourHandler(
              { userId: user?.id, tourName: "createWorkspace" },
              dispatch,
            );

            sendUserLogs({
              userId: user?.id,
              userEmail: user?.email,
              type: "createWorkspace",
            });
          }}
          steps={stepsCreateWorkspace}
          current={currentStepCreateWorkspaceTour}
        />
      )}

      {userTours && !isShowCreateWorkspaceTour && data.length > 0 && (
        <Tour
          open={isShowOpenWorkspaceTour}
          onClose={() => {
            setIsShowOpenWorkspaceTour(false);

            finishUserTourHandler(
              { userId: user?.id, tourName: "openWorkspace" },
              dispatch,
            );
          }}
          steps={stepsOpenWorkspace}
          current={currentStepOpenWorkspaceTour}
        />
      )}

      <SideNavigation active={"HOME"} />
      <div className="header_work_space">
        <div className="item_header">
          <div className="wrapper_button">
            <div className="title">Workspace</div>
            <div className="description">Create your own workspace</div>
            <div
              className="button"
              onClick={() =>
                setCurrentStepCreateWorkspaceTour(prev => prev + 1)
              }
            >
              <button
                ref={refOnboardingStart}
                onClick={() => {
                  modalRef.current.open();
                }}
              >
                Create
              </button>
            </div>
          </div>
          <div>
            <div
              className="but_div"
              onClick={() => {
                modalRef.current.open();
              }}
            ></div>
            <CreateWorkSpace></CreateWorkSpace>
          </div>
        </div>
        <div className="item_header">
          <div className="wrapper_button">
            <div className="title">Getting started</div>
            <div className="description">
              Read more about the first steps in the UBOS platform
            </div>
            <div className="button">
              {/* <button>Choose</button> */}
              <a
                href="https://documentation.ubos.tech/docs/intro"
                rel="noreferrer"
                target="_blank"
              >
                Documentation
              </a>
            </div>
          </div>
          <div style={{ width: "205px" }}>
            <TemplateWorkSpace></TemplateWorkSpace>
          </div>
        </div>
        <div className="item_header">
          <div className="wrapper_button">
            <div className="title">Community</div>
            <div className="description">Explore our guides and tutorials</div>
            <div className="button">
              {/* <button>Choose</button> */}
              <a
                href="https://community.ubos.tech/"
                rel="noreferrer"
                target="_blank"
              >
                Explore
              </a>
            </div>
          </div>
          <div style={{ width: "205px" }}>
            <ComunitiWorkSpace width="217px" height="165px"></ComunitiWorkSpace>
          </div>
        </div>
        <div className="item_header">
          <div className="wrapper_button">
            <div className="title">Discord</div>
            <div className="description">Join our Discord community</div>
            <div className="button">
              {/* <button>Choose</button> */}
              <a
                href="https://discord.gg/dt59QaptH2"
                rel="noreferrer"
                target="_blank"
              >
                Jump In
              </a>
            </div>
          </div>
          <div style={{ width: "205px" }}>
            <Disclcon width="210px" height="155px"></Disclcon>
          </div>
        </div>
      </div>
      <MainContainer>
        <div className="container_header">
          <div className="wrapper_title">
            <div className="title">Workspaces</div>
            <div className="create">
              <span
                onClick={() => {
                  modalRef.current.open();
                }}
              >
                +
              </span>
            </div>
            <div className="search">
              <input
                type="text"
                onChange={e => {
                  childRef.current.filter(e.target.value);
                }}
              />
              <SearchIcon></SearchIcon>
            </div>
          </div>
          {/* <div className="filter">
            <Filter></Filter>
            <span>Filter</span>
          </div> */}
        </div>
        <Styles ref={refShowAllWorkspace}>
          <div>
            {isFetchingApplications ? (
              <StyledSkeleton
                active
                title={false}
                paragraph={{
                  width: "100%",
                  rows: 6,
                }}
              />
            ) : (
              <Table
                userColumns={columns}
                data={data}
                ref={childRef}
                // We added this as a prop for our table component
                // Remember, this is not part of the React Table API,
                // it's merely a rendering option we created for
                // ourselves
                renderRowSubComponent={renderRowSubComponent}
              />
            )}
          </div>
        </Styles>
      </MainContainer>
      <CreateOrgModal
        refEnterWorkspaceName={refEnterWorkspaceName}
        ref={modalRef}
      ></CreateOrgModal>
    </WrapperWorkspace>
  );
};

export default WorkSpace;
