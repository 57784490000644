import { createIdTemplateManager } from "pages/Editor/DoclLayoutSettings";
import { Dispatch } from "redux";
import { ReduxActionTypes } from "../constants/ReduxActionConstants";
// import { CustomTitleTabElement } from "../pages/Editor/DoclLayoutSettings";

export const openCreateServicePage = (dispatch: Dispatch<any>, props?: any) => {
  dispatch({ type: ReduxActionTypes.FETCH_DATASOURCES_INIT });
  const newItem = {
    id: new Date().toISOString(),
    component: "nodeRed",
    title: "New Service",
    props: `https://us.${location.host}/Services%20new`,
    // props: `https://us.platform.eks.ubraine.com/Services%20new`,
    queryParams: props?.queryParams,
  };
  dispatch({
    type: ReduxActionTypes.SET_LAYOUT_ITEM,
    payload: newItem,
  });
};

export const openTemplateManager = (dispatch: Dispatch<any>) => {
  dispatch({ type: ReduxActionTypes.FETCH_DATASOURCES_INIT });

  // const newItem = {
  //   id: createIdTemplateManager(),
  //   component: "TemplateManager",
  //   title: "Asset Marketplace",
  // };

  // dispatch({
  //   type: ReduxActionTypes.SET_LAYOUT_ITEM,
  //   payload: newItem,
  // });
  const newItem = {
    id: JSON.stringify({
      id: "TemplateManagerV2",
      type: "TemplateManagerV2",
    }),
    component: "TemplateManagerV2",
    props: {
      url: "https://ubos.tech/templates",
      title: "Asset Marketplace",
    },
  };

  dispatch({
    type: ReduxActionTypes.SET_LAYOUT_ITEM,
    payload: newItem,
  });
};

export const openServiceSetting = (dispatch: Dispatch<any>, item: any) => {
  dispatch({
    type: ReduxActionTypes.SET_LAYOUT_ITEM,
    payload: {
      id: "nodeRedId",
      title: `ServiceSetting ${item.name}`,
      component: "serviceSetting",
      props: {
        id: item.id,
        name: item.name,
        subscription_id: item.datasourceConfiguration.subscriprion_id,
      },
    },
  });
};

export const openLayoutItem = (dispatch: any, item: any) => {
  //   const itemActions: any = {
  //     serviceSetting: {
  //       title: (item: any) => `ServiceSetting ${item.name}`,
  //       component: "serviceSetting",
  //       props: (item: any) => ({
  //         id: item.id,
  //         name: item.name,
  //         subscription_id: item.datasourceConfiguration.subscriprion_id,
  //       }),
  //     },
  //     api: {
  //       title: (item: any) => item.settings_data.projectName,
  //       component: "nodeRed",
  //       props: (item: any) => item.settings_data.urlNodeRed,
  //     },
  //     ui: {
  //       title: (item: any) => item.name,
  //       component: "nodeRed",
  //       props: (item: any) => {
  // const path = item.type === "ui" ? "/home/edit/" : "";
  // return `https://${item.datasourceConfiguration.url}${path}`;
  //       },
  //     },
  //   };

  //   const action = itemActions[item.type];

  //   //   if (!action) {
  //   //     console.error(`Не відомий тип дії: ${type}`);
  //   //     return;
  //   //   }

  //   const title =
  //     typeof action.title === "function" ? action.title(item) : action.title;
  //   const component = action.component;
  //   const props =
  //     typeof action.props === "function" ? action.props(item) : action.props;

  const payload = {
    id: "nodeRedId",
    title: item.title,
    component: "nodeRed",
    props: item.props,
  };

  console.log(payload, "itemitemitem");

  //   dispatch({
  //     type: ReduxActionTypes.SET_LAYOUT_ITEM,
  // payload: {
  //   id: "nodeRedId",
  //   title,
  //   component,
  //   props,
  // },
  //   });
};
